/* Provide sufficient contrast against white background */
a { color: #052759; }

code { color: #e01a76; }

@font-face {
    font-family: 'OMVProgress';
    src: local('OMVProgress'), url(./fonts/OMVProgress.woff) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }
body, .ms-Button, div, button, tspan, label {
    font-family: 'OMVProgress','Segoe UI'!important;
}
.ms-Nav-linkText, .ms-Nav-chevronButton, h1, h2, h3, h4, h5, .ms-CommandBarItem-link, .ms-Panel-headerText{
    color:#052759!important;
}
.ms-DetailsRow-cell{
    font-size:14px!important;
}
.btn-primary {
    background-color: #052759;
    border-color: #1861ac;
    color: #fff;
}

.paginationContainer { margin-top: 15px; }

.paginationContainer ul {
    display: inline-block;
    margin-left: 20px;
    padding-left: 0;
    width: auto;
}

.paginationContainer li {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 3px;
    white-space: nowrap;
}

.paginapaginationContainertion li a {
    color: #000;
    display: inline-block;
    outline: none;
    padding: 2px 5px;
}

.paginationContainer li.active {
    background: rgb(224, 224, 224);
    outline: none;
}

.paginationContainer li.disabledPagination a,
.paginationContainer li.disabledPagination a:hover,
.paginationContainer li.disabledPagination a:focus,
.paginationContainer li.disabledPagination,
.paginationContainer li.disabledPagination:focus {
    border: none;
    color: #aaa;
    cursor: default;
}

.Toastify { z-index: 999; }

#root > div > div.ms-Grid > div:nth-child(1) > div > div > div > div > div > div > div.ms-OverflowSet.ms-CommandBar-primaryCommand.primarySet-101 > div:nth-child(3) {
    margin: 0 auto;
}